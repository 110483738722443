import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import AllContext from './context/AllContext';
import 'react-modal-video/scss/modal-video.scss';
import 'react-image-lightbox/style.css';
import './index.scss';
import 'bootstrap/dist/js/bootstrap.min.js';
import '../src/data/i18n.js';
// import './fonts/Gilroy-ExtraBold.otf';
// import './fonts/Gilroy-Light.otf';

// import ReactGA from 'react-ga';
// const TRACKING_ID = "G-PX2NT49QKM"; // OUR_TRACKING_ID
// ReactGA.initialize( TRACKING_ID );








// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

ReactDOM.render(
  <React.Fragment>
    <AllContext>
      <App />
    </AllContext>
  </React.Fragment>,
  document.getElementById('root')
);








// ReactGA.pageview(window.location.pathname + window.location.search);


// const SendAnalytics = ()=> {
//   ReactGA.send({
//     hitType: "pageview",
//     page: window.location.pathname,
//   });
// }


// reportWebVitals(SendAnalytics);