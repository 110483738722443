import React from 'react';
import HomeOneHeader from './HomeOneHeader/HomeOneHeader';
import HomeHeroSlider from './HomeHeroArea/HomeHeroSlider';
import HomeFeatures from './HomeFeatures/HomeFeatures';
import HomeAbout from './HomeAbout/HomeAbout';
import HomeCapabilities from './HomeCapabilities/HomeCapabilities';
import HomeServices from './HomeServices/HomeServices';
// import HomeCounterArea from './HomeCounterArea/HomeCounterArea';
// import HomeTeams from './HomeTeams/HomeTeams';
// import HomePricing from './HomePricing/HomePricing';
import HomeBrands from './HomeBrands/HomeBrands';
import HomeContact from './HomeContact/HomeContact';
import Footer from '../../components/shared/Footer';
import CommonCtaArea from '../../components/CommonCtaArea/CommonCtaArea';
import PageHelmet from '../../components/shared/PageHelmet';
import HomeTwoFeatures from '../HomeTwo/HomeTwoFeatures/HomeTwoFeatures';

import CookieConsent from "react-cookie-consent";
import { useTranslation } from "react-i18next";
import CoreDetails from '../CorePrelaunch/Prelaunch/CoreDetails';


const Home = () => {
  const { t } = useTranslation();

    return (
        <>
            <PageHelmet pageTitle="Home" />
            <HomeOneHeader />
            <HomeHeroSlider />

            <HomeTwoFeatures/>
            {/* <HomeFeatures /> */}
            
            <HomeAbout />
            <HomeCapabilities />
            {/* <HomeCounterArea/> */}
            {/* <HomeTeams/> */}
            <CoreDetails/>

            <CommonCtaArea/>
            <HomeServices/>

            {/* <HomePricing/> */}
            <HomeBrands/>
            <HomeContact/>
            <Footer/>


            <CookieConsent
                location="bottom"
                buttonText={t("cookies_button")}
                cookieName="myAwesomeCookieName2"
                style={{ background: "#00ffff", color: "#000" }}
                buttonStyle={{ background: "#fff", color: "#000", fontSize: "20px", borderRadius: "5px", paddingLeft: "15px", paddingRight: "15px" }}
                expires={150}>

            <a href="/cookies-policies">{t("cookies_text")}</a>
            
            </CookieConsent>




        </>
    );
};

export default Home;