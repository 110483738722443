import React from 'react';
import CommonPageHeader from '../../components/CommonPageHeader/CommonPageHeader';
import Footer from '../../components/shared/Footer';
import PageHelmet from '../../components/shared/PageHelmet';
import HomeOneHeader from '../Home/HomeOneHeader/HomeOneHeader';
import PortfolioArea from './PortfolioArea/PortfolioArea';
import PortfolioSelfApp from './PortfolioArea/PortfolioSelfApp';
import { useTranslation } from "react-i18next";


const Portfolio = () => {
  const { t } = useTranslation();

   return (
      <>
         <PageHelmet pageTitle="Portfolio Page" />

         <HomeOneHeader/>
         <CommonPageHeader title={t("page_title_portfolio")} subtitle={t("page_title_portfolio")} />

         {/* <PortfolioSelfApp/> */}
         <PortfolioArea/>

         <Footer/>
      </>
   );
};

export default Portfolio;