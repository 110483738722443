import React from 'react';
import Slider from 'react-slick';
import useGlobalContext from '../../../hooks/useGlobalContext';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";


// const lngs = [
//   { code: "en", native: "English" },
//   { code: "es", native: "Spanish" },
// ];


const HomeHeroArea = () => {
  const { t } = useTranslation();


  //  const { setIsVideoOpen} = useGlobalContext();
   // slider data

   const homeSliderData = [
     {
       id: 1,
       bgImg: "home_slider_1",
       text1: t("home_slider_title_main_copy_one1"),
      //  text2: t("home_slider_title_main_copy_one2"),
       title: t("home_slider_title_superior_one"),
       colorBtn: "z-btn",
       theKey: true,
       textColor: "#ffffff",
       miniTextColor: "#00ffff",

     },
     {
       id: 2,
       bgImg: "home_slider_2",
       text1: t("home_slider_title_main_copy_two1"),
      //  text2: t("home_slider_title_main_copy_two2"),
       title: t("home_slider_title_superior_two"),
       colorBtn: "z-btn z-btn-transparent",
       theKey: false,
       textColor: "#e8e8e8",
       miniTextColor: "#e8e8e8",
     },
   ];

  
   // slick setting
   const settings = {
      autoplay: true,
      autoplaySpeed: 8000,
      speed: 500,
      infinite: true,
      dots: false,
      fade: true,
      arrows: false,
      slidesToShow: 1,
      slidesToScroll: 1,
   };

   return (
      <>
         {/* <VideoPopup videoId="yJg-Y5byMMw" /> */}

         <section className="slider__area">
               <Slider className='slider-active' {...settings}>
                {
                  homeSliderData.map((slider,index) => {
                     return (

                       <div
                         key={index}
                         className={`single-slider slider__height d-flex align-items-center ${slider.bgImg}`}
                       >

                         <div className="slider__shape">
                           <img
                             className="shape triangle"
                             src="assets/img/icon/slider/triangle.svg"
                             alt="triangle"
                           />
                           <img
                             className="shape dotted-square"
                             src="assets/img/icon/slider/dotted-square.svg"
                             alt="dotted-square"
                           />
                           <img
                             className="shape solid-square"
                             src="assets/img/icon/slider/solid-square.svg"
                             alt="solid-square"
                           />

                           {/* <img
                             className="shape circle"
                             src="assets/img/icon/slider/circle.svg"
                             alt="circle"
                           /> */}

                         </div>
                         <div className="container h1_slider_wrapper">
                           <div className="row">

                             <div className="col-xl-9 col-lg-9 col-md-10 col-sm-10">
                               <div className="slider__content">
                                 <span style={{color: slider.miniTextColor }}>
                                   {slider.title}
                                 </span>
                                 <h1 style={{color: slider.textColor }}>
                                   {" "}
                                   {slider.text1} <br /> {slider.text2}
                                 </h1>

                                {slider.theKey && 
                                 (<img
                                    // className="shape dotted-square"
                                    src="assets/img/slider/theKey.svg"
                                    alt="theKey"
                                  />
                                )}

                                 {/* <br></br>
                                 <br></br>
                                 <br></br>
                                 <br></br> */}
                                 <div className="slider__btn">
                                   <Link
                                     to="/contact"
                                     className={slider.colorBtn}
                                   >
                                     {t('contact_us')}
                                   </Link>
                                 </div>
                               </div>
                             </div>

                             <div className="col-xl-3 col-lg-3 col-md-2 col-sm-2">
                               {/* <div className="slider__play">
                                    <button onClick={() => setIsVideoOpen(true)} className="slider__play-btn">
                                       <i> <FiPlay/> </i></button>
                                 </div> */}

                             </div>
                           </div>
                         </div>
                       </div>
                     );
                  })
                }
               </Slider>
         </section>
      </>
   );
};

export default HomeHeroArea;