import React from 'react';
import { BiMap } from 'react-icons/bi';
import { FaEnvelope, FaFacebookF, FaLinkedin, FaInstagram, FaPhoneAlt, FaTwitter, FaVimeoV } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { businessLogic } from '../../../lib/constants';
import PrelaunchForm from '../Prelaunch/prelaunchForm';
import { useTranslation } from "react-i18next";


const CoreDetails = () => {
   const { t } = useTranslation();

   return (
      <>
         <section className="team__details pt-120 pb-160">
            <div className="container">
               <div className="team__details-inner p-relative white-bg">
                  <div className="team__details-shape p-absolute">
                     <img src="assets/img/icon/team/shape-1.png" alt=""/>
                  </div>
                  <div className="row">
                     <div className="col-xl-6 col-lg-6">
                        <div className="team__details-img w-img mr-70">
                           <img src="assets/img/core/invokeprelaunch.png" alt=""/>
                           {/* <img src="assets/img/team/details/invokeapp.png" alt=""/> */}
                        </div>
                     </div>
                     <div className="col-xl-6 col-lg-6">
                        <div className="team__details-content pt-105">
                           <span> {t("core_coreprelaunch_title_mini")} </span>
                           <h3>{t("core_coreprelaunch_title_slogan")}</h3>
                           <p>{t("core_coreprelaunch_description")}</p>
                           
                           

                            <PrelaunchForm/>



                           <div className="team__details-social theme-social" >
                              <ul>

                                 <li>
                                 <a href={`${businessLogic.linkedin}`} target="blank">
                                       <i ><FaLinkedin/> </i>
                                       <i ><FaLinkedin/> </i>
                                    </a>
                                 </li>

                                 <li>
                                 <a href={`${businessLogic.instagram}`} target="blank">
                                       <i ><FaInstagram/> </i>
                                       <i ><FaInstagram/> </i>
                                    </a>
                                 </li>

                                 <li>
                                 <a href={`${businessLogic.facebook}`} target="blank">
                                       <i ><FaFacebookF/></i>
                                       <i ><FaFacebookF/></i>
                                    </a>
                                 </li>

                              </ul>
                           </div>











                        </div>
                     </div>
                  </div>
               </div>
               <div className="row">
                  <div className="col-xl-10 offset-xl-1">
                     <div className="team__details-info mt-60">
                        <h4 >{t("core_coreprelaunch_title_more_info")}</h4>
                        <p >{t("core_coreprelaunch_description1_more_info")}</p>
                        <p >{t("core_coreprelaunch_description2_more_info")}</p>

                        <Link to="/contact#contact" className="z-btn mt-10">{t("core_coreprelaunch_button_more_information")}</Link>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default CoreDetails;
