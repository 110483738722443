import React, { useEffect } from 'react';
import CommonCtaArea from '../../components/CommonCtaArea/CommonCtaArea';
import CommonPageHeader from '../../components/CommonPageHeader/CommonPageHeader';
import Footer from '../../components/shared/Footer';
import PageHelmet from '../../components/shared/PageHelmet';
import HomeOneHeader from '../Home/HomeOneHeader/HomeOneHeader';
import { useTranslation } from "react-i18next";
import DownloadEbookResources from '../HomeTwo/HomeTwoTestimonial/HomeTwoTestimonial';
import CommercialDossier from './CommercialDossier';
import CalendlyWidget from './CalendyWidget';



const Resources = () => {
  const { t } = useTranslation();


   return (
      <>
         <PageHelmet pageTitle="Resources" />

         <HomeOneHeader/>
         <CommonPageHeader title={t("page_title_resources")} subtitle={t("page_title_resources")} />

         {/* <HomePricing/> */}
         {/* <TermPolicies/> */}

        <DownloadEbookResources />

        <CommercialDossier/>


        <CalendlyWidget
            url="https://calendly.com/invokeapp/15min?primary_color=7c74ff" 
            style={{ minWidth: "320px", height: "900px" }} 
        />




         {/* <HomeServices />
         <HomeCapabilities/>
         <HomeThreeSecondServices /> */}
         <CommonCtaArea/>
         <Footer/>
      </>
   );
};






export default Resources;







