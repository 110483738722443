import React, { useState, useEffect, useContext } from "react";

import { CgArrowLongLeft, CgArrowLongRight } from "react-icons/cg";
// import { FiPlay } from "react-icons/fi";
// import { IoEllipsisHorizontal } from "react-icons/io5";
// import { Link } from "react-router-dom";
// import BlogSingleItem from '../../../components/BlogSingleItem/BlogSingleItem';
// import VideoPopup from '../../../components/VideoPopup/VideoPopup';
// import useGlobalContext from "../../../hooks/useGlobalContext";
import BlogLeftSideBar from "./BlogLeftSideBar";

import { constants } from "../../../lib/constants";
import LoaderSpiner from "../../../components/LoadersSpiner/loader";

import { ContainerArticles } from "./blogComponents";
import Paginate from "./paginationBlog";

import { AppContext } from "../../../context/AllContext";





const BlogsArea = () => {
  let getArticles = constants.getLastArticles;
  const { language } = useContext(AppContext);


  const [articles, setArticle] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(4);

  useEffect(() => {
    fetch(getArticles+'?language='+language)
      .then((res) => res.json())
      .then((data) => setArticle(data))
      .catch((e) => console.log(e));

  }, []);

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = articles.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const previousPage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = () => {
    if (currentPage !== Math.ceil(articles.length / postsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  // const { setIsVideoOpen} = useGlobalContext();
  return (
    <>
      {/* <VideoPopup videoId="yJg-Y5byMMw"/> */}

      <section className="blog__area pt-120 pb-120">
        <div className="container">
          <div className="row">
            <div className="col-xl-8 col-lg-8">
              <div className="blog__wrapper mr-50">













                {articles.length > 0 ? (
                  <ContainerArticles data={currentPosts} />
                ) : (
                  <LoaderSpiner />
                )}













                {/* ARTICLE ENTRY WITH VIDEO*/}
                {/* <BlogSingleItem image="3" user_image="2" name="Weir Doe" title="The Importance of Instagram Metrics and Where to Find Them!" video_icon={<div className="blog__play p-absolute">
                           <button onClick={() => setIsVideoOpen(true)} data-fancybox> <i> <FiPlay/> </i></button>
                        </div>} /> */}

                {/* QUOTE WITH OTHER STYLE */}
                {/* <div className="blog__quote blog__quote-2 mb-50 p-relative white-bg fix"
                        >
                           <div className="blog__quote-line">
                              <img className="line-3" src="assets/img/icon/blog/line-3.png" alt="" />
                              <img className="line-4" src="assets/img/icon/blog/line-4.png" alt="" />
                           </div>
                           <div className="blog__link-icon">
                              <i className="icon_link_alt icon_1"></i>
                              <i className="icon_link_alt icon_2"></i>
                           </div>
                           <p>Oxford ruddy off his nut, bum bag is lavatory baking cakes bubble and squeak cheesed off what
                              a plonker.!</p>
                        </div> */}

                {articles ? (
                  <div className="basic-pagination">
                    <ul>
                      <li onClick={previousPage}>
                        <a href="#0">
                          <i>
                            {" "}
                            <CgArrowLongLeft />{" "}
                          </i>
                          <i>
                            {" "}
                            <CgArrowLongLeft />{" "}
                          </i>
                        </a>
                      </li>

                      
                      <Paginate
                        postsPerPage={postsPerPage}
                        totalPosts={articles.length}
                        paginate={paginate}
                        previousPage={previousPage}
                        nextPage={nextPage}
                        currentPage={currentPage}
                      />


                      <li onClick={nextPage}>
                        <a href="#0">
                          <i>
                            {" "}
                            <CgArrowLongRight />{" "}
                          </i>
                          <i>
                            {" "}
                            <CgArrowLongRight />{" "}
                          </i>
                        </a>
                      </li>
                    </ul>
                  </div>
                ) : (
                  <LoaderSpiner />
                )}
              </div>
            </div>

            <BlogLeftSideBar />
          </div>
        </div>
      </section>
    </>
  );
};

export default BlogsArea;
