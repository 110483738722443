import React, { useState } from "react";
import Checkbox from "@mui/material/Checkbox";

import { constants } from "../../../lib/constants";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";

// import useAnalyticsEventTracker from "../../../services/useAnalyticsEventTracker";








let endpointComment = constants.postCommentBlog;

const FormCommentBlog = ({ uuid }) => {
  const { t } = useTranslation();

  // const gaEventTracker = useAnalyticsEventTracker("SendComment");

  const [postId, setPostId] = useState(null);

  const [name, setName] = useState();
  const [comment, setMessage] = useState();
  const [email, setEmail] = useState();
  const [subscribe, setSubscribe] = useState(true);

  const resetForm = () => {
    setName("");
    setMessage("");
    setEmail("");
    setSubscribe(false)
  };





  const handleButtonClick = () => {
    // gaEventTracker("PostContactUs");

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        name,
        email,
        comment,
        subscribe,
      }),
    };
    fetch(endpointComment+uuid, requestOptions)
      .then((response) => {
        console.log(response.status);
        if (response.status === 201) {
          setPostId(response.status);
          toast.success( t("generic_toast_success_send_message_moderation") );
          // toast.success("Message sent succesfully!");
          resetForm();
        } else {
          setPostId(null); // Si hubo un error, establece postId a null
          toast.error(t("generic_toast_error_notsend_message"));
        }
      })
      .catch((error) => {
        setPostId(null); // Si hubo un error, establece postId a null
      });
  };







  return (
    <>
      <form action="#">
        <div className="row">
          <div className="col-xl-12">
            <div className="post-input">
              <textarea placeholder={t("generic_your_message")} name="message" value={comment} onChange={(e) => setMessage(e.target.value)}></textarea>
            </div>
          </div>
          <div className="col-xl-6 col-md-6">
            <div className="post-input">
              <input type="text" placeholder={t("generic_your_name")} name="name" value={name} onChange={(e) => setName(e.target.value)}/>
            </div>
          </div>
          <div className="col-xl-6 col-md-6">
            <div className="post-input">
              <input type="email" placeholder={t("generic_your_email")} name="email" value={email} onChange={(e) => setEmail(e.target.value)}/>
            </div>
          </div>

          {/* <div className="col-xl-12">
          <div className="post-check mb-40">
            <input type="checkbox" defaultChecked style={{ color: "red" }} />
            <span>
              I consent to my name and email being stored and used to send me
              updates and news.
            </span>
          </div>
        </div> */}

          <div className="col-xl-12">
            <div className="post-check mb-40">
              <Checkbox style={{ color: "#7c74ff" }} defaultChecked value={subscribe} onChange={(e) => setSubscribe(e.target.value === "on" ? true : false)}/>
              
              {/* <a href="/comments-policies">{t("blog_newsletter_policy_two")}</a> */}
              <label >&nbsp;&nbsp;&nbsp;{t("blog_newsletter_policy_one")}<a href="/comments-policies">{t("blog_newsletter_policy_two")}</a></label>

              
              <label className="custom-checkbox-label">
              {t("blog_comments_consent")} 
              </label>
            </div>
          </div>
        </div>

        <button type="button" className="z-btn" onClick={handleButtonClick}>
          {t("generic_send_message")}
        </button>
      </form>
      <ToastContainer />
    </>
  );
};

export default FormCommentBlog;
