import React from 'react';
import { Link } from 'react-router-dom';
import {PostContactUs} from '../../../components/ContactForm/formAction';


// import useAnalyticsEventTracker from '../../../services/useAnalyticsEventTracker';
import { businessLogic, colors } from '../../../lib/constants';
import { useTranslation } from "react-i18next";


const HomeContact = () => {
   const { t } = useTranslation();

   // const gaEventTracker = useAnalyticsEventTracker('Contact us');


   return (
      <>
         <section className="contact__area" id='contactUsHome'> 
            <div className="container-fluid p-0">
               <div className="row g-0">
                  <div className="col-xl-12">

                     <div className="contact__map" >
                        {/* <iframe title='contact' src="https://maps.google.com/maps?hl=en&amp;q=Dhaka+()&amp;ie=UTF8&amp;t=&amp;z=10&amp;iwloc=B&amp;output=embed"></iframe> */}
                        <div className="contact__wrapper d-md-flex justify-content-between" style={{ backgroundColor: colors.mainGrey }}>
                           <div className="contact__info mr-100" >
                              <h3>{t("home_contact_title")}</h3>
                              <ul>
                                 
                                 <li>
                                    <h4>{t("home_contact_address")}</h4>
                                    <p>{businessLogic.address}</p>
                                 </li>

                                 {/* <li>
                                    <h4>call us</h4>
                                    <p>
                                       <a href={`${businessLogic.phoneNumber}`} onClick={()=>gaEventTracker('call')}>{businessLogic.phoneNumber}</a>
                                    </p>
                                 </li> */}

                                 <li>
                                    <h4>{t("home_contact_email")}</h4>
                                    <p>
                                       <a href={`mailto:${businessLogic.emailBase}`}>
                                          {businessLogic.emailBase}
                                       </a>
                                    </p>
                                 </li>

                                 {/* <li>
                                    <h4>WhatsApp</h4>
                                    <div onClick={()=>gaEventTracker('WhatsApp-HomePage')}>
                                    <div>
                                       <Link to="" className="z-btn z-btn-transparent mt-10" style={{ backgroundColor: '#25D366' }} >WhatsApp</Link>
                                    </div>
                                 </li> */}

                              </ul>
                           </div>


                           <PostContactUs/>

                           {/* <div className="contact__form">
                              <form >
                                 <input type="text" placeholder="Your Name"/>
                                 <input type="email" placeholder="Your Email"/>
                                 <textarea placeholder="Your Message"></textarea>
                                 <button type="button" className="z-btn " >Send Message</button>
                              </form>
                           </div> */}

                           </div>



                        </div>

                     </div>
                  </div>
               </div>
         </section>
      </>
   );
};

export default HomeContact;